import { useMemo } from 'react'

import { useUserRoles } from './use-user-roles'

import { Right } from '../../const'
import { RoleRights } from '../../const/role-rights'

export const useUserRights = (): Right[] => {
    const userRoles = useUserRoles()

    return useMemo(() => {
        const set = userRoles.reduce((result, role) => {
            const rights = RoleRights[role] || []

            rights.forEach((right) => result.add(right))

            return result
        }, new Set<Right>())

        return [...set]
    }, [userRoles])
}
