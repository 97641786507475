import { Session } from 'next-auth'

export const REFRESH_ACCESS_TOKEN_ERROR = 'RefreshAccessTokenError'

export const hasRefreshAccessTokenError = (session: Session): boolean =>
    session?.error ? session.error.startsWith(REFRESH_ACCESS_TOKEN_ERROR) : false

export const isRefreshAccessTokenError = (error?: any): boolean =>
    error instanceof RefreshAccessTokenError || error?.name === 'RefreshAccessTokenError'

export class RefreshAccessTokenError extends Error {
    context: any

    constructor(message: string, context?: any) {
        super(message)

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, RefreshAccessTokenError.prototype)

        this.name = 'RefreshAccessTokenError'
        this.context = context

        Error.captureStackTrace(this, RefreshAccessTokenError)
    }

    toString() {
        let serializedContext

        try {
            serializedContext = JSON.stringify(this.context)
        } catch (error) {
            serializedContext = this.context
        }

        return `${REFRESH_ACCESS_TOKEN_ERROR}: ${this.message}\n${serializedContext}`
    }
}
