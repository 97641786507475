'use client'

import { styled } from '@mui/material'

export const Footer = styled('footer')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.light.lightShade,
    gap: theme.spacing(1),
    padding: theme.spacing(2, 4),
}))
