export const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]

    if (!base64Url) {
        throw new Error('Cannot parse jwt token')
    }

    const base64 = base64Url.replace('-', '+').replace('_', '/')

    return JSON.parse(Buffer.from(base64, 'base64').toString())
}
