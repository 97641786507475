import { useSession } from './use-session'

import { Role } from '../../const'
import { filterRoles } from '../../const/enum/claim'

export const useUserRoles = (): Role[] => {
    const { session } = useSession()
    const { roles } = session || {}

    return filterRoles(roles || [])
}
