export type SchemaEntry<T> = {
    key: keyof T
    type: ValidTypes
    nullable: boolean
}

type ValidTypes = 'string' | 'number' | 'boolean' | 'object' | 'array'

export const isValueValid = (value: unknown, expectedType: ValidTypes, nullable: boolean): boolean => {
    // If the value is null return nullable
    if (value === null) {
        return nullable
    }

    // Special handling for 'array' since typeof array in JavaScript returns 'object'
    if (expectedType === 'array') {
        return Array.isArray(value)
    }

    // For other types, directly compare typeof the value with the expected type
    return typeof value === expectedType
}
