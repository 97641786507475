import {
    AllCountriesPostfix,
    CountryAccessGroup,
    CountryAccessGroupClaim,
    CountryAccessGroupClaimPrefix,
    UsersCountryAccessInfo,
} from './country-access-group'
import { Role } from './role'

import { BusinessUnit } from '../../../lib/generated/graphql'

export type Claim = Role | CountryAccessGroupClaim

const isCountryAccessGroupClaim = (claim: Claim): claim is CountryAccessGroupClaim => claim.startsWith(CountryAccessGroupClaimPrefix)

const isRoleClaim = (claim: Claim): claim is Role => !claim.startsWith(CountryAccessGroupClaimPrefix)

export const filterRoles = (claims: Claim[]): Role[] => claims.filter(isRoleClaim)

export const filterCountryAccessGroups = (claims: Claim[]): UsersCountryAccessInfo[] => {
    return claims.filter(isCountryAccessGroupClaim).map((claim) => {
        const lowerCaseClaim = claim.toLowerCase()

        if (lowerCaseClaim.endsWith(AllCountriesPostfix)) {
            return {
                countryAccessGroup: AllCountriesPostfix.toUpperCase(),
            }
        }

        const [countryAccessGroup, businessUnit] = lowerCaseClaim.replace(`${CountryAccessGroupClaimPrefix}_`, '').split('_')
        return {
            countryAccessGroup: countryAccessGroup?.toUpperCase() as CountryAccessGroup,
            businessUnit: businessUnit?.toUpperCase() as BusinessUnit,
        }
    })
}
