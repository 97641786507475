import { Typography } from '@mui/material'

import { FormDrawerProps, RenderProps } from './form-drawer'

import { ExitIcon } from '../../icons'
import { Header } from '../../layout/drawer/header'

type FormDrawerHeaderProps = Pick<FormDrawerProps, 'title'> & Pick<RenderProps, 'toggleOpenDrawer'>

export const FormDrawerHeader: React.FC<FormDrawerHeaderProps> = ({ title, toggleOpenDrawer }) => {
    return (
        <Header>
            <Typography variant="h3" color="dark.simple">
                {title}
            </Typography>
            <ExitIcon sx={{ fontSize: 18 }} cursor="pointer" onClick={toggleOpenDrawer} />
        </Header>
    )
}
