import { Session } from 'next-auth'

import { SchemaEntry, isValueValid } from '../../../utils/is-valid-value'

// This function checks if the session is valid.
// In some cases, the session might be set to an empty object {}.
// We consider both a null session and an empty object as invalid sessions.

const sessionSchema: SchemaEntry<Session>[] = [
    { key: 'error', type: 'string', nullable: true },
    { key: 'expires_at', type: 'number', nullable: false },
    { key: 'roles', type: 'array', nullable: false },
    { key: 'email', type: 'string', nullable: true },
    { key: 'ldap_id', type: 'string', nullable: true },
]

export const isValidSession = (session: Session | null): session is Session => {
    if (!session || typeof session !== 'object' || Object.keys(session).length === 0) {
        return false
    }

    return sessionSchema.every(({ key, type, nullable }) => isValueValid(session[key], type, nullable))
}
