import { Session } from 'next-auth'

interface AuthBreadcrumbParams {
    message: string
    session: Session | null
    source?: string
}

export const authBreadcrumb = ({ message, session, source }: AuthBreadcrumbParams) => {
    const formattedMessage = source ? `[${source}] ${message}` : message
    return {
        category: 'auth',
        message: formattedMessage,
        data: { session },
        level: 'info' as const,
    }
}
