import React from 'react'

import { useUserRights } from './use-user-rights'

import { Right } from '../../const'

export const WithUserRight: React.FC<{ children: React.ReactNode; right: Right }> = ({ children, right }) => {
    const userRights = useUserRights()

    if (!userRights.includes(right)) {
        return null
    }

    return <>{children}</>
}
