'use client'

import React, { useState } from 'react'

import { Box, Divider, Drawer, DrawerProps } from '@mui/material'

import { FormButton, FormButtonProps } from './form-button'
import { FormDrawerFooter } from './form-drawer-footer'
import { FormDrawerHeader } from './form-drawer-header'

import { palette } from '../../../theme/palette'

export type RenderProps = {
    toggleOpenDrawer: () => void
    submitting: boolean
    setSubmitting: (disabled: boolean) => void
}

export type FormDrawerProps = FormButtonProps & {
    children: (renderProps: RenderProps) => React.ReactNode
    title: string
    formId: string
    drawerProps?: Omit<DrawerProps, 'open'>
    confirmLabel?: string
}

export const FormDrawer: React.FC<FormDrawerProps> = ({ buttonTitle, buttonType, children, confirmLabel, drawerProps, title, formId }) => {
    const [open, setOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const toggleOpen = () => setOpen(!open)

    return (
        <>
            <FormButton buttonTitle={buttonTitle} buttonType={buttonType} toggleOpenDrawer={toggleOpen} />
            <Drawer
                anchor="right"
                open={open}
                onClose={toggleOpen}
                PaperProps={{
                    sx: {
                        background: palette.light.lightShade40,
                        width: {
                            md: 750,
                        },
                    },
                }}
                {...drawerProps}>
                <FormDrawerHeader title={title} toggleOpenDrawer={toggleOpen} />

                <Divider />

                <Box paddingX={4} paddingY={2} flex={1} overflow="auto">
                    {children({
                        toggleOpenDrawer: toggleOpen,
                        submitting,
                        setSubmitting,
                    })}
                </Box>

                <FormDrawerFooter formId={formId} submitting={submitting} toggleOpenDrawer={toggleOpen} confirmLabel={confirmLabel} />
            </Drawer>
        </>
    )
}
