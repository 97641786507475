import { useContext } from 'react'

import { Session } from 'next-auth'

import { AuthContext } from './authentication-provider'

export const useSession = (): { session: Session | null } => {
    const contextSession = useContext(AuthContext)

    return {
        session: contextSession,
    }
}
