import { getTokenExpInMs } from './get-token-exp-in-ms'

export const hasExpiredToken = (token: string, buffer: number = 0): boolean => {
    // Add the buffer (in milliseconds) to the current time.
    // If buffer is not provided, it defaults to 0 (no additional buffer).
    const currentTimeWithBuffer = Date.now() + buffer

    // Compare the adjusted current time with the token expiration time.
    return getTokenExpInMs(token) <= currentTimeWithBuffer
}
