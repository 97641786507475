import { Button } from '@mui/material'

import { PlusCircleIcon } from '../../icons'

export const AddButton: React.FC<{ onClick: () => void; title: string }> = ({ onClick, title }) => {
    return (
        <Button onClick={onClick} color="secondary" startIcon={<PlusCircleIcon color="secondary" />}>
            {title}
        </Button>
    )
}
