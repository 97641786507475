'use client'

import { styled } from '@mui/material'

export const Header = styled('header')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.light.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
}))
